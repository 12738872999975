import NextHead from 'next/head';

interface Props {
  title?: string;
  description?: string;
}

const Head = ({ title, description }: Props) => (
  <NextHead>
    <title>{['Colette', title].filter((item) => item).join(' - ')}</title>
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    {description && <meta name="description" content={description} />}
    <meta
      name="viewport"
      content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
    />
    <meta httpEquiv="X-UA-Compatible" content="ie=edge" key="UA-compatible" />
    <link href="https://api.tiles.mapbox.com/mapbox-gl-js/v2.9.2/mapbox-gl.css" rel="stylesheet" />
  </NextHead>
);

export default Head;
