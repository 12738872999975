import NextImage, { ImageLoaderProps } from 'next/image';

const normalizeSrc: (src: string) => string = (src) => {
  return src[0] === '/' ? src.slice(1) : src;
};

const cloudflareLoader = ({ src, width, quality }: ImageLoaderProps): string => {
  if (src.startsWith('/')) return src;

  const params = [`width=${width}`];
  if (quality) {
    params.push(`quality=${quality}`);
  }

  const paramsString = params.join(',');

  return `${process.env.NEXT_PUBLIC_ASSETS_URL}/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`;
};

export const Image = (props: React.ComponentProps<typeof NextImage>) => {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <NextImage loader={cloudflareLoader} {...props} />;
};
