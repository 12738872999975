import Notification from 'components/Notification';
import { INotification } from 'lib/redux/notifications';

interface Props {
  notifications: INotification[];
  onClickClose: (id: string) => void;
}

const NotificationsComponent = ({ notifications, onClickClose }: Props) => (
  <div className="z-50 fixed bottom-2 left-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
    {notifications.map((notification: INotification) => (
      <Notification
        message={notification.message}
        severity={notification.severity}
        onClose={() => onClickClose(notification.id as string)}
        key={notification.id}
      />
    ))}
  </div>
);

export default NotificationsComponent;
