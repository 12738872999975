import { forwardRef } from 'react';
import Loader from 'components/Loader';
import { cn } from 'lib/cn';

interface Props extends React.ComponentPropsWithRef<'button'> {
  loading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, Props>(
  ({ children, loading, disabled, className, type = 'submit', ...props }, ref) => (
    <button
      type={type}
      className={cn(
        'px-8 btn focus:outline-none disabled:cursor-not-allowed disabled:hover:bg-gray-600 relative overflow-hidden',
        className
      )}
      disabled={loading || disabled}
      {...props}
      ref={ref}
    >
      {children}

      {loading ? (
        <span className="absolute inset-0 grid place-items-center bg-gray-600 text-black">
          <Loader />
        </span>
      ) : null}
    </button>
  )
);

export default Button;
