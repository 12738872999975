import clsx from 'clsx';

interface Props {
  message: string;
  severity: 'success' | 'error';
  onClose: () => void;
}

const Notification = ({ message, severity, onClose }: Props) => (
  <div
    className={clsx(
      severity === 'success' && 'bg-green-600',
      severity === 'error' && 'bg-red-600',
      'max-w-sm px-4 w-full shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'
    )}
  >
    <div className="p-4">
      <div className="flex items-center">
        <div className="flex-1 flex justify-between">
          <p className="flex-1 text-sm font-sans font-medium text-base text-white">{message}</p>
        </div>
        <div className="ml-4 flex-shrink-0 flex">
          <button
            type="button"
            className="bg-transparent rounded-md inline-flex text-white hover:text-gray-400"
            onClick={() => onClose()}
          >
            <span className="sr-only">Close</span>
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default Notification;
