import clsx from 'clsx';

const Loader = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={clsx('w-5 h-5 mx-auto animate-spin', props.className)}
  >
    <circle cx="12" cy="12" r="10.5" stroke="url(#paint0_linear)" strokeWidth="3" />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="12"
        y1="0"
        x2="12"
        y2="18"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="currentColor" />
        <stop offset="1" stopColor="currentColor" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default Loader;
