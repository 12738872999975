import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'lib/redux/notifications';
import Component from './component';

const { getCurrentNotifications } = selectors;
const { removeNotification } = actions;

const Notification = () => {
  const dispatch = useDispatch();
  const currentNotifications = useSelector(getCurrentNotifications);

  const onClickClose: (id: string) => void = (id) => {
    dispatch(removeNotification(id));
  };

  return <Component notifications={currentNotifications} onClickClose={onClickClose} />;
};

export default Notification;
